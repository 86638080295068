export default class ExampleCard extends React.PureComponent

  # Used for targets that should be italicized
  EM_TARGET_REGEX = /^__(.*)__$/

  constructor: (props) ->
    super(props)

  render: ->
    classes = "card #{@props.className}"

    <div className={classes}>
      <div className="container-lg">
        { @renderToolbar() }
        { @renderExample() }
      </div>
      <p className="legend">
        {@props.example.legend}
      </p>
    </div>

  renderToolbar: ->
    <div className="row toolbar">
      <div className="col col-6">
        {@props.example.sourceLanguage}
      </div>
      <div className="col col-6">
        {@props.example.targetLanguage}
      </div>
      <div className="language-arrow">
        <i className="fas fa-arrow-right"></i>
      </div>
    </div>

  renderExample: ->
    <div className="row row-cols-1 row-cols-md-2 example">
      <div className="col">
        <div className="source">
          <i className="fas fa-user d-block d-md-none"></i>
          {@props.example.source}
        </div>
      </div>
      <div className="col">
        <div className="target with-neuro" title={@renderHoverTitle('neuro')}>
          <i className="service-icon"></i>
          <i className="fas fa-circle-check"></i>
          { @renderTarget(@props.example.targets.neuro) }
        </div>
        <div className="target with-deepl" title={@renderHoverTitle('deepl')}>
          <i className="service-icon"></i>
          <i className="fas fa-circle-xmark"></i>
          { @renderTarget(@props.example.targets.deepl) }
        </div>
        <div className="target with-google" title={@renderHoverTitle('google')}>
          <i className="service-icon"></i>
          <i className="fas fa-circle-xmark"></i>
          {@renderTarget(@props.example.targets.google)}
        </div>
      </div>
    </div>

  renderHoverTitle: (service) ->
    defaultHoverTitles = {
      neuro:  @props.i18n.translatedNeuro,
      deepl:  @props.i18n.translatedDeepl,
      google: @props.i18n.translatedGoogle
    }

    customHoverTitles = @props.example.customHoverTitles || {}

    hoverTitle =  customHoverTitles[service] || defaultHoverTitles[service]
    hoverTitle += " (#{@props.example.dates[service]})" if @props.example.dates && @props.example.dates[service]

    hoverTitle

  renderTarget: (target) ->
    if typeof target == 'object'
      for key, value of target
        @renderSubTarget(key, value)
    else
      if target.match(EM_TARGET_REGEX)
        <em>
          {target.replace(EM_TARGET_REGEX, "$1")}
        </em>
      else
        target

  renderSubTarget: (key, value) ->
    <span key={key}>
      {value} <small>[{@props.i18n[key]}]</small>
    </span>
